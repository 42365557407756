@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css?family=Exo:400,700&display=swap");
.footer {
  background-color: #000;
  bottom: 0px;
  padding: 1rem 0; }
  @media screen and (max-width: 600px) {
    .footer {
      display: flex; } }
  .footer .wrap {
    position: relative;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 600px) {
      .footer .wrap {
        flex-direction: column; } }

.footer__logo {
  margin: 0.25rem 1rem 0 0; }
  .footer__logo img {
    height: 50px; }

.footer__links a {
  margin: 0 1rem 0 0;
  color: #fff;
  text-decoration: none; }

.footer__copyright {
  position: absolute;
  right: 0;
  margin: 0 1rem 0 4rem;
  color: #fff;
  font-size: 0.75em; }
  @media screen and (max-width: 600px) {
    .footer__copyright {
      position: relative;
      margin: 1rem 0; } }
