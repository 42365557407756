@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css?family=Exo:400,700&display=swap");
.loading {
  padding: 5rem;
  font-family: "Exo", sans-serif;
  font-size: 3rem;
  color: #363b3b;
  text-align: center;
  animation: Pulse 1s infinite; }

.flex {
  display: flex; }
  .flex.vert {
    flex-direction: column; }

.wrap {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto; }

.content {
  padding-top: 40px;
  overflow: hidden; }
  @media screen and (max-width: 600px) {
    .content {
      padding-top: 0px; } }

@keyframes Pulse {
  0% {
    color: #e2e2e2; }
  50% {
    color: #363b3b; }
  100% {
    color: #e2e2e2; } }
