@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css?family=Exo:400,700&display=swap");
header {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 10000;
  background: white;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1); }
  header a {
    text-decoration: none; }
  header.active {
    height: 100%; }
    header.active .main-menu {
      width: 100%;
      height: 90vh;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0px; }
      header.active .main-menu .main-menu__links {
        width: 100%;
        height: 80%;
        display: flex;
        margin: 0px; }
        header.active .main-menu .main-menu__links ul {
          flex-direction: column;
          height: 100%;
          align-items: center;
          justify-content: space-around;
          padding-top: 4.25rem; }
          header.active .main-menu .main-menu__links ul li {
            width: 100%;
            justify-content: center;
            display: flex;
            align-items: center; }
            header.active .main-menu .main-menu__links ul li a {
              text-decoration: none; }
              header.active .main-menu .main-menu__links ul li a span {
                font-size: 2.5em; }
      header.active .main-menu .main-menu__toggle {
        position: absolute;
        right: 20px; }
      header.active .main-menu .main-menu__logo {
        margin: 0px;
        width: 100%;
        background: red;
        display: none; }
  header.mod-hidden {
    background: white; }
    header.mod-hidden .main-menu__logo {
      margin: 0 1rem 0 0; }
      header.mod-hidden .main-menu__logo .mainlogo, header.mod-hidden .main-menu__logo .mobilelogo {
        width: auto; }
      header.mod-hidden .main-menu__logo .mainlogo {
        height: 75px;
        top: -58px; }
      @media screen and (max-width: 600px) {
        header.mod-hidden .main-menu__logo .mainlogo {
          display: none; }
        header.mod-hidden .main-menu__logo .mobilelogo {
          display: block; } }

.localebar {
  width: 100%;
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center; }

.localebar__loc {
  height: 100%;
  display: flex;
  justify-content: end;
  padding-right: 2.4rem; }

.localebar__loc__icon {
  margin: 0 5px; }

.localebar__loc__select {
  background: lightgray;
  border: 0px solid white;
  border-radius: 0px; }
  .localebar__loc__select option {
    border-radius: 0px;
    padding: 2px; }

.main-menu {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  padding: 10px 0px; }
  .main-menu .main-menu__logo {
    margin: 0 1rem 0 0; }
  .main-menu .main-menu__links {
    margin: 0 1rem 0 0; }
  @media screen and (max-width: 600px) {
    .main-menu {
      padding: 20px; } }
  .main-menu.centered {
    justify-content: center;
    margin-left: -150px; }
    .main-menu.centered .main-menu__links {
      margin: 0px; }
    .main-menu.centered .main-menu__logo {
      margin: 0px; }

.main-menu__logo {
  position: relative;
  cursor: pointer; }
  .main-menu__logo .mainlogo, .main-menu__logo .mobilelogo {
    height: 150px;
    width: auto; }
  .main-menu__logo .mainlogo {
    display: block;
    top: -20px;
    position: absolute; }
  .main-menu__logo .mobilelogo {
    display: none;
    position: absolute;
    top: -10px; }
  @media screen and (max-width: 600px) {
    .main-menu__logo .mainlogo {
      display: none; }
    .main-menu__logo .mobilelogo {
      display: block;
      height: 75px;
      top: -37.5px; } }

@media screen and (max-width: 600px) {
  .main-menu__links {
    display: none; } }

.main-menu__links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%; }
  .main-menu__links ul li {
    float: left; }
    .main-menu__links ul li:hover {
      background-color: red; }
      .main-menu__links ul li:hover a {
        color: #fff; }
    .main-menu__links ul li a {
      padding: 1rem;
      font-size: 1rem;
      color: #000;
      font-weight: black;
      font-family: "Exo", sans-serif;
      text-decoration: none;
      cursor: pointer; }

.main-menu__loc {
  margin: 0 1rem 0 0; }

.main-menu__toggle {
  cursor: pointer;
  color: #ed2228;
  display: none;
  font-size: 2rem; }
  .main-menu__toggle .bars {
    display: block; }
    .main-menu__toggle .bars.active {
      display: none; }
  .main-menu__toggle .exit {
    display: none; }
    .main-menu__toggle .exit.active {
      display: block; }
  @media screen and (max-width: 600px) {
    .main-menu__toggle {
      display: block; } }
